import { PageProps } from "gatsby"
import moment from "moment"
import React, { useContext, useState } from "react"
import { useMediaQuery } from "react-responsive"
import ArrowButton from "../../components/atom/ArrowButton"
import PageTitleText from "../../components/atom/PageTitleText"
import AnnouncementItem from "../../components/molecule/AnnouncementItem"
import EmptyItem from "../../components/molecule/EmptyItem"
import Layout from "../../components/organism/Layout"
import { globalContext } from "../../contexts/GlobalContext"
import { indexContext } from "../../contexts/IndexContext"
import useAnnouncement from "../../contexts/IndexContext/hooks/useAnnouncement"
import { DataType } from "../../types"
import { ANNOUNCEMENT_SECTION_TITLE, MEDIA_QUERY, NO_ANNOUNCEMENT, PAGE } from "../../values"
const styles = require("./style.module.scss")

const Announcements = ({ location: { state } }: PageProps) => {
  const isPc = useMediaQuery({
    query: MEDIA_QUERY,
  })

  const announcementHook = useContext(globalContext)?.announcementHook

  const [selectedAnnouncementIndex, setSelectedAnnouncementIndex] = useState<number>(
    (state && state.index) || 0
  )

  const textAnnouncement = [
    {
      id: 0,
      title: "안녕",
      content: "테스트하고싶어",
      date: "2021-03-02",
    },
  ]

  return (
    <Layout nowPage={PAGE.ANNOUNCEMENT}>
      <div className={styles["root"]}>
        <div className={styles["container"]}>
          <div className={styles["titleListContainer"]}>
            <div className={styles["titleContainer"]}>
              <PageTitleText title={ANNOUNCEMENT_SECTION_TITLE} />
            </div>

            {announcementHook?.data && announcementHook?.data.length ? (
              <div ref={announcementHook?.moblieRef} className={styles["indexList"]}>
                {announcementHook?.data.map((item, index) => (
                  <button
                    className={styles["announcementButton"]}
                    onClick={() => setSelectedAnnouncementIndex(index)}
                    style={{ alignItems: "center", marginRight: 10 }}
                    key={index.toString()}
                  >
                    <div
                      className={
                        styles[
                          `announcement${selectedAnnouncementIndex == index ? "Selected" : ""}`
                        ]
                      }
                    >
                      {index + 1}
                    </div>
                    <span
                      className={
                        styles[
                          `announcementDateText${
                            selectedAnnouncementIndex == index ? "Selected" : ""
                          }`
                        ]
                      }
                    >
                      {moment(item.date).format("MM/DD")}
                    </span>
                  </button>
                ))}
              </div>
            ) : (
              <div className={styles["annoucementEmpty"]}>
                <div className={styles["emptyItemList"]}>
                  <img
                    src={require("../../images/announcement.svg")}
                    className={styles["announcementIcon"]}
                  />
                </div>
                <span className={styles["noAnnouncementText"]}>{NO_ANNOUNCEMENT}</span>
              </div>
            )}

            <div
              ref={announcementHook?.ref}
              className={styles["itemList"]}
              style={{
                borderBottom:
                  announcementHook?.ref.current?.scrollHeight >
                  announcementHook?.ref.current?.clientHeight
                    ? "3px solid #d4d4d4"
                    : undefined,
              }}
            >
              {announcementHook?.data && announcementHook?.data.length ? (
                announcementHook?.data.map((item, index) => (
                  <AnnouncementItem
                    announcement={item}
                    index={index + 1}
                    length={announcementHook.data.length}
                    onClick={() => setSelectedAnnouncementIndex(index)}
                    isSelected={index === selectedAnnouncementIndex}
                    key={index.toString()}
                  />
                ))
              ) : (
                <div className={styles["emptyItemList"]}>
                  <img
                    src={require("../../images/announcement.svg")}
                    className={styles["announcementIcon"]}
                  />
                  <span className={styles["noAnnouncementText"]}>{NO_ANNOUNCEMENT}</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles["infoContainer"]}>
            <div className="v-box">
              <div
                className="h-box"
                style={{
                  width: "100%",
                  justifyContent: "center",

                  paddingBottom: 20,
                  borderBottom: "0.5px solid rgb(212, 212, 212)",
                  marginBottom: 20,
                }}
              >
                <div className={styles["navigationBar"]}>
                  <ArrowButton
                    arrowDirection="LEFT"
                    disabled={selectedAnnouncementIndex <= 0}
                    onClick={() => setSelectedAnnouncementIndex(selectedAnnouncementIndex - 1)}
                    isRed={true}
                    isAnnouncement={true}
                  />
                  <span className={styles["announcementTitle"]}>
                    {announcementHook?.data &&
                      announcementHook?.data.length != 0 &&
                      announcementHook?.data[selectedAnnouncementIndex].title}
                  </span>
                  <ArrowButton
                    arrowDirection="RIGHT"
                    disabled={
                      announcementHook?.data
                        ? selectedAnnouncementIndex >= announcementHook?.data.length - 1
                        : true
                    }
                    onClick={() => setSelectedAnnouncementIndex(selectedAnnouncementIndex + 1)}
                    isRed={true}
                    isAnnouncement={true}
                  />
                </div>
              </div>
              <span className={styles["announcementDate"]}>
                {announcementHook?.data &&
                  announcementHook?.data.length != 0 &&
                  moment(announcementHook?.data[selectedAnnouncementIndex].date).format(
                    "YYYY-MM-DD"
                  )}
              </span>
              <pre
                className={styles["announcementContent"]}
                style={{ background: "rgb(250, 250, 250)", padding: 0, whiteSpace: "pre-wrap" }}
              >
                {announcementHook?.data &&
                  announcementHook?.data.length != 0 &&
                  announcementHook?.data[selectedAnnouncementIndex].content}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Announcements
